import React, { useEffect, useRef } from 'react'
import classNames from 'classnames/bind'
import { RichText } from 'prismic'
import { useViewportScroll } from 'framer-motion'
import { useWindowHeight } from '@react-hook/window-size'
import { useScrollRig } from '@14islands/r3f-scroll-rig'

import Layout from 'layouts'

import useUIContext from 'context/ui'

import { Container } from 'components/ui/layout'
import AspectRatio from 'components/ui/aspect-ratio'
import Image from 'components/ui/image'
import SEO from 'components/seo'
import Slices from 'components/ui/slices'

import * as s from './JobPage.module.css'

const cn = classNames.bind(s)

const JobPage = ({
  data: {
    prismicJob: { data },
  },
}) => {
  if (!data) return null

  const { scrollY } = useViewportScroll()
  const { reflow } = useScrollRig()
  const windowHeight = useWindowHeight(1000)
  const setHeaderTheme = useUIContext(s => s.setHeaderTheme)

  const imageWrapperRef = useRef(null)

  const {
    page_meta_title,
    page_meta_description,
    page_meta_thumbnail,
    title,
    location,
    thumbnail,
    category,
    background_color,
    hero_dark_mode,
    body,
  } = data || {}

  const thumbnailRatio = thumbnail?.dimensions?.height / thumbnail?.dimensions?.width || 0.66

  const handleScroll = value => {
    const imageTop = imageWrapperRef.current?.offsetTop || 0
    const imageHeight = imageWrapperRef.current?.clientHeight || 0

    const isOnTop = value <= Math.max(imageTop + imageHeight / 2 - windowHeight / 2, windowHeight / 3)

    const theme = hero_dark_mode && isOnTop ? 'dark' : 'light'

    document.documentElement.setAttribute('data-theme', theme)
    document.documentElement.style.background = isOnTop ? background_color : 'var(--color-background)'

    setHeaderTheme(theme)
  }

  useEffect(() => {
    if (hero_dark_mode) {
      setHeaderTheme('dark')
    }

    const timeout = setTimeout(reflow, 1500)

    return () => {
      clearTimeout(timeout)
      setHeaderTheme()
    }
  }, [])

  useEffect(() => {
    const unsubscribeScrollY = scrollY.onChange(handleScroll)

    return () => {
      unsubscribeScrollY()
    }
  }, [windowHeight])

  const theme = hero_dark_mode ? 'dark' : 'light'

  return (
    <Layout className={s.aboutPage} background={background_color} theme={theme} labCTA={false} fadeIn={true}>
      <SEO title={page_meta_title} description={page_meta_description} thumbnail={page_meta_thumbnail} />

      <div className={cn('hero')} data-theme={theme}>
        <Container>
          <div className={cn('heroWrapper')}>
            <span className={cn('info')}>
              {category?.document?.data?.name} | {location}
            </span>

            <h1 className={cn('title')}>{RichText.asText(title.richText)}</h1>

            <div className={cn('imageWrapper')} ref={imageWrapperRef}>
              <AspectRatio ratio={thumbnailRatio} className={cn('image')}>
                <Image src={thumbnail?.url} alt={thumbnail?.alt} className={cn('image')} />
              </AspectRatio>
            </div>
          </div>
        </Container>
      </div>

      <div className={cn('slices')}>{body?.map(Slices)}</div>
    </Layout>
  )
}

export default JobPage
