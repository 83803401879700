import { graphql } from 'gatsby'

import { JobFragment } from 'fragments/Job'

import JobPage from 'views/job/JobPage'

export const query = graphql`
  query JobPageQuery($uid: String) {
    prismicJob(uid: { eq: $uid }, lang: { eq: "en-us" }) {
      _previewable
      ...JobFragment
    }
  }
`

JobPage.query = query
JobPage.fragments = [JobFragment]

export default JobPage
